import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'reflect-metadata';
import NextApp from 'next/app';
import { useRouter } from 'next/router';

import { client } from '~/api/graphql/client';
import { AuthenticationProvider } from '~/components/AuthenticationProvider/AuthenticationProvider';
import { BrainfishButton } from '~/components/Brainfish/Brainfish';
import { EppoProvider } from '~/components/Eppo/EppoProvider';
import { FeatureFlagsProvider } from '~/components/FeatureFlags/FeatureFlagsProvder';
import { getFeatureFlags } from '~/components/FeatureFlags/utils';
import { GoogleOneTapLoginProvider } from '~/components/GoogleOAuthProvider/GoogleOneTapLoginProvider';
import { Layout } from '~/components/Layout/Layout';
import { ResponsiveContextProvider } from '~/components/ResponsiveContextProvider/ResponsiveContextProvider';
import { UserLoggedInProvider } from '~/components/UserAuthProvider/UserAuthProvider';
import { UserDetailsProvider } from '~/components/UserDetailsProvider/UserDetailsProvider';
import { getUserInfo } from '~/components/UserDetailsProvider/utils';
import { AnalyticsInitiator } from '~/components/analytics/AnalyticsInitiator/AnalyticsInitiator';
import { AnalyticsPageTracker } from '~/components/analytics/AnalyticsPageTracker/AnalyticsPageTracker';
import { shouldShowChatWidget } from '~/utils/chatWidget';
import { inferScreenWidthFromUserAgent } from '~/utils/userAgent';

import type { NextPageContext } from 'next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import type { ReactElement } from 'react';
import type { FeatureFlag } from '~/common/types/settings';
import type { UserDetails } from '~/common/types/user';

import '@madpaws/design-system/dist/global.css';
import '@madpaws/design-system/dist/components.css';

type CustomProps = {
  featureFlags: FeatureFlag[];
  inferredScreenWidth: number;
  userInfo: {
    numberOfUnreadMessages: number | null;
    userDetails: UserDetails | null;
  };
};

type Props = AppProps & CustomProps;

const App = ({
  Component,
  pageProps,
  featureFlags,
  inferredScreenWidth,
  userInfo,
}: Props): ReactElement => {
  const router = useRouter();

  return (
    <ApolloProvider client={client}>
      <ResponsiveContextProvider inferredScreenWidth={inferredScreenWidth}>
        <FeatureFlagsProvider featureFlags={featureFlags}>
          <AnalyticsInitiator>
            <AnalyticsPageTracker>
              <UserLoggedInProvider>
                <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_SDK_CLIENT_ID || ''}>
                  <AuthenticationProvider route={router.pathname}>
                    <GoogleOneTapLoginProvider route={router.pathname}>
                      <UserDetailsProvider userInfo={userInfo}>
                        <EppoProvider>
                          <Layout from={router.query.from} route={router.pathname}>
                            <Component {...pageProps} />
                            {shouldShowChatWidget(
                              inferredScreenWidth,
                              router.pathname,
                              router.query.from
                            ) && <BrainfishButton />}
                          </Layout>
                        </EppoProvider>
                      </UserDetailsProvider>
                    </GoogleOneTapLoginProvider>
                  </AuthenticationProvider>
                </GoogleOAuthProvider>
              </UserLoggedInProvider>
            </AnalyticsPageTracker>
          </AnalyticsInitiator>
        </FeatureFlagsProvider>
      </ResponsiveContextProvider>
    </ApolloProvider>
  );
};

type InitialProps = AppInitialProps & CustomProps;

App.getInitialProps = async (appContext: AppContext & NextPageContext): Promise<InitialProps> => {
  const appProps = await NextApp.getInitialProps(appContext);
  const inferredScreenWidth = inferScreenWidthFromUserAgent(appContext.ctx.req);
  const featureFlags = await getFeatureFlags();
  const userInfo = await getUserInfo(appContext);

  return { ...appProps, featureFlags, inferredScreenWidth, userInfo };
};

export default App;
