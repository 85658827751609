import { PET_STORE_BASE_LINK } from '~/common/constants/externalLinks';

// Header link labels
export const FIND_A_SITTER_LINK_LABEL = 'Find a Sitter';
export const HELP_LINK_LABEL = 'Help';
export const BECOME_A_SITTER_LINK_LABEL = 'Become a Sitter';
export const MESSAGES_LINK_LABEL = 'Messages';
export const FAVOURITES_LINK_LABEL = 'Favourites';
export const MY_PROFILE_LINK_LABEL = 'My Profile';
export const MY_PETS_LINK_LABEL = 'My Pets';
export const PAYMENT_LINK_LABEL = 'Payment';
export const ACCOUNT_LINK_LABEL = 'Account';
export const BOOKINGS_LINK_LABEL = 'Bookings';
export const PETS_LINK_LABEL = 'Pets';
export const CALENDER_LINK_LABEL = 'Calendar';
export const DASHBOARD_LINK_LABEL = 'Dashboard';
export const PET_STORE_LINK_LABEL = 'Pet Store';
export const HOW_IT_WORKS_LINK_LABEL = 'How it works';
export const MAD_PAWS_STORE_LINK_LABEL = 'Mad Paws Store';
export const REFERRAL_LINK_LABEL = 'Get $25';

// Page Name for URL mapping
export const SERVICES_AND_RATES_PAGE_NAME = 'Services and Rates';
export const SERVICES_AND_RATES_VIEW_PAGE_NAME = 'Services and Rates View';
export const HOME_PAGE_NAME = 'Home';
export const HOW_IT_WORKS_PAGE_NAME = 'How It Works';
export const PET_PROFILE_VIEW_PAGE_NAME = 'Pet Profile View';
export const SEARCH_RESULTS_PAGE_NAME = 'Search Results';
export const SERVICE_LANDING_PAGE_NAME = 'Service Landing Page';
export const SITTER_PROFILE_PAGE_NAME = 'Sitter Profile';

export const PET_STORE_LINK = `${PET_STORE_BASE_LINK}/?utm_source=madpaws&utm_medium=futures&utm_campaign=header`;
