import { init } from '@eppo/js-client-sdk';
import Cookies from 'js-cookie';
import { createContext, useEffect, useState } from 'react';

import { SEGMENT_ANONYMOUS_ID_COOKIE_NAME } from '~/common/constants/user';

import { trackEvent } from '../analytics/analytics';
import { EPPO_RANDOMISED_ASSIGNMENT_EVENT_NAME } from '../analytics/constants';

import type { ReactElement } from 'react';

const NEXT_PUBLIC_EPPO_API_KEY = process.env.NEXT_PUBLIC_EPPO_API_KEY as string;

interface IEppoRandomizationProvider {
  children: ReactElement;
}

export const EppoContext = createContext({
  isInitialized: false,
  isError: false,
});

export const EppoProvider = ({ children }: IEppoRandomizationProvider): ReactElement | null => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEppoReady, setIsEppoReady] = useState(false);
  const [segmentIsReady, setSegmentIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  /**
   * This ensures segment anonymous id is available before initializing Eppo
   * Check if the segment anonymous id is already present in the cookies
   * If it is, set the segmentIsReady to true and return
   * If not, check every 100ms for 10 times if the id is present in the cookies
   * If not present after 10 attempts, set segmentIsReady to true and return
   * Because we want to initialise eppo even without the segment anonymous id
   */
  useEffect(() => {
    if (!!Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME)) {
      setSegmentIsReady(true);
      return;
    }

    let getSegmentattempts = 0;
    const getSegmentMaxAttempts = 10;
    const getSegmentInterval = setInterval(() => {
      getSegmentattempts++;
      const segmentAnonymousIdFromCookies = !!Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME);

      if (segmentAnonymousIdFromCookies) {
        setSegmentIsReady(true);
        clearTimeout(getSegmentInterval);
      } else if (getSegmentattempts >= getSegmentMaxAttempts) {
        setSegmentIsReady(true);
        clearInterval(getSegmentInterval); // Stop after 10 attempts
      }
    }, 100);

    return () => clearTimeout(getSegmentInterval);
  }, [setSegmentIsReady]);

  /**
   * Initialize Eppo with the provided API key
   * If the initialization is successful, set isEppoReady to true
   */
  useEffect(() => {
    init({
      apiKey: NEXT_PUBLIC_EPPO_API_KEY,
      assignmentLogger: {
        logAssignment(assignment) {
          trackEvent(EPPO_RANDOMISED_ASSIGNMENT_EVENT_NAME, { ...assignment });
        },
      },
    })
      .then(() => setIsEppoReady(true))
      .catch(() => {
        setIsError(true);
        console.warn('Failed to initialize Eppo');
      });
  }, []);

  /**
   * If Eppo and Segment are ready, set isInitialized to true
   * This will allow Eppo to get the assignment for the user
   */
  useEffect(() => {
    if (isEppoReady && segmentIsReady) {
      setIsInitialized(true);
    }
  }, [isEppoReady, segmentIsReady]);

  return <EppoContext.Provider value={{ isInitialized, isError }}>{children}</EppoContext.Provider>;
};
