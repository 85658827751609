import * as EppoSdk from '@eppo/js-client-sdk';
import { useMediumToMediaQuery, useSmallToMediaQuery } from '@madpaws/design-system';
import Cookies from 'js-cookie';
import { useContext, useMemo } from 'react';

import { SEGMENT_ANONYMOUS_ID_COOKIE_NAME } from '~/common/constants/user';

import { EppoContext } from './EppoProvider';
import { useUserDetailsContext } from '../UserDetailsProvider/UserDetailsProvider';

type Context = {
  isError: boolean;
  isInitialized: boolean;
};

const eppoClient = EppoSdk.getInstance();
const defaultValue = 'off';

export const useEppo = (): Context => useContext(EppoContext);

export const useEppoStringAssignment = (featureKey: string): string => {
  const { isInitialized } = useEppo();
  const { user } = useUserDetailsContext();
  const isSmallToViewport = useSmallToMediaQuery();
  const isMediumToViewport = useMediumToMediaQuery();

  const segmentAnonymousId = Cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE_NAME) as string;

  /**
   * Determine the device type based on the viewport size
   */
  const deviceType = useMemo(() => {
    if (isSmallToViewport) {
      return 'mobile_web';
    }
    if (isMediumToViewport) {
      return 'tablet_web';
    }

    return 'desktop_web';
  }, [isMediumToViewport, isSmallToViewport]);

  const subjectAttributes = useMemo(
    () => ({
      city: user?.city || '',
      isSitter: user?.isSitter !== undefined ? user.isSitter : 'unset',
      state: user?.state || '',
      postCode: user?.postcode || '',
      userId: user?.id || '',
      deviceType,
    }),
    [user, deviceType]
  );

  const subjectKey = useMemo(() => segmentAnonymousId, [segmentAnonymousId]);

  /**
   * Get the assignment for the given feature key
   * If the eppo client is not initialized, return the default value
   */
  const assignment = useMemo(() => {
    if (!isInitialized) {
      return defaultValue;
    }

    return eppoClient.getStringAssignment(featureKey, subjectKey, subjectAttributes, defaultValue);
  }, [featureKey, subjectKey, subjectAttributes, isInitialized]);

  return assignment;
};
