import { Button } from '@madpaws/design-system';
import { useRouter } from 'next/router';
import React from 'react';

import { SITTER_PROFILE_PREFIX } from '~/common/constants/app';
import { getServiceTypeIdFromSearchFilters } from '~/common/utils/search';
import { useAuthDialog } from '~/components/AuthenticationProvider/useAuthDialog';
import { trackEvent } from '~/components/analytics/analytics';
import { CLICKED_CONTACT_SITTER_EVENT_NAME } from '~/components/analytics/constants';
import { mapSearchFiltersToUrlQueryParameters } from '~/components/utils/search';

import styles from './SitterProfileMobileStickyFooter.module.css';
import { FavoriteButton } from '../components/FavoriteButton/FavoriteButton';
import { getDefaultEventProperties } from '../tracking/getDefaultEventProperties';
import { calculateNumberOfDays, countPets, hasLessOrEqualCharactersThan } from '../utils';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type { SitterProfileStickyComponent } from '~/common/types/sitter';

type SitterProfileMobileStickyFooterProps = {
  isSitterLiked: boolean;
  searchFilters: SearchFilters;
  setIsSitterLiked: (isSitterLiked: boolean) => void;
  sitterProfileSlug: string | string[];
  stickyFooter: SitterProfileStickyComponent;
};

export const SitterProfileMobileStickyFooter = ({
  isSitterLiked,
  setIsSitterLiked,
  searchFilters,
  sitterProfileSlug,
  stickyFooter,
}: SitterProfileMobileStickyFooterProps): ReactElement => {
  const router = useRouter();

  const { baseRates, firstName, sitterId } = stickyFooter;
  const {
    chronology: { startDate, endDate },
    location: {
      address: { suburb, stateName },
    },
    petTypes,
    service: serviceType,
  } = searchFilters;
  const FIRST_NAME_LIMIT = 10;
  const shouldDisplayFirstName = hasLessOrEqualCharactersThan(FIRST_NAME_LIMIT, firstName);
  const serviceTypeIdFromSearchFilters = getServiceTypeIdFromSearchFilters(serviceType);
  const { basePrice, timeUnit } =
    baseRates.find(({ serviceTypeId: id }) => id === serviceTypeIdFromSearchFilters) ??
    baseRates[0];

  const { checkLoginAndOpenAuthDialog } = useAuthDialog();

  const LOCATION_SLUG = `${suburb.toLowerCase()}-${stateName.toLowerCase()}`;
  const SITTER_SLUG = sitterProfileSlug;
  const SEARCH_QUERY_PARAMS = mapSearchFiltersToUrlQueryParameters(searchFilters);

  const handleClick = async (): Promise<void> => {
    await router.push(
      `/${SITTER_PROFILE_PREFIX}/${LOCATION_SLUG}/${SITTER_SLUG}/booking-enquiry?${SEARCH_QUERY_PARAMS}`
    );
  };

  const handleContactSitter = (): void => {
    trackEvent(CLICKED_CONTACT_SITTER_EVENT_NAME, {
      booking_value: basePrice,
      num_time_units: calculateNumberOfDays(startDate, endDate),
      base_service_type: serviceType.type,
      num_pets: countPets(petTypes),
      pet_types: petTypes,
      ...getDefaultEventProperties(sitterId),
    });

    checkLoginAndOpenAuthDialog(
      handleClick,
      `Create a free account or log in to contact ${firstName}`,
      `/${SITTER_PROFILE_PREFIX}/${LOCATION_SLUG}/${SITTER_SLUG}/booking-enquiry?${SEARCH_QUERY_PARAMS}`
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.priceInformation}>
          <span>From</span>
          <strong className={styles.basePrice}>${basePrice}</strong>
          <span>a {timeUnit}</span>
        </div>
        <div className={styles.contactSitterButton}>
          <Button
            label={shouldDisplayFirstName ? `Contact ${firstName}` : `Contact sitter`}
            onClick={handleContactSitter}
          />
        </div>
        <FavoriteButton
          firstName={firstName}
          isSitterLiked={isSitterLiked}
          setIsSitterLiked={setIsSitterLiked}
          sitterId={sitterId}
        />
      </div>
      <div className={styles.notice}>You won&apos;t be asked to pay yet.</div>
    </div>
  );
};
